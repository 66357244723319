import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import "./assets/scss/customStyle.css";
import Spinner from "./views/spinner/Spinner";
import "./data";
import { Provider } from "react-redux";
import { store, persistor} from "./redux/Store";
import { PersistGate } from 'redux-persist/integration/react'
// setup fake backend
// import { ConfigureFakeBackend } from "./jwt/_helpers";
// ConfigureFakeBackend();


const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./App")), 0);
    })
);

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
       <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
       <App />
      </PersistGate>
        
       </Provider>
  </Suspense>,
  document.getElementById("root")
);
 