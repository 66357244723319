import { types } from '../actiontypes'

const initialState = {
	isLoading: false,
	trainer:[],
	
}

export  const trainerReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case types.GET_TRAINERS.start :
			return { ...state, isLoading: true }
		case types.GET_TRAINERS.success:
			return { ...state, isLoading: false, trainer: payload !== null ? payload : [] }
        case types.GET_TRAINERS.failed :
            return { ...state, isLoading: false }
			case types.GET_TRAINERS.start :
			return { ...state, isLoading: true }
		case types.GET_TRAINERS.success:
			return { ...state, isLoading: false, trainer: payload !== null ? payload : [] }
        case types.GET_TRAINERS.failed :
            return { ...state, isLoading: false }
    
		default:
			return state
	}

}