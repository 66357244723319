import { types } from "../actiontypes";

const initialState = {
  isLoading: false,
  booking: [],
  bookingRejected:[],
  bookingCompleted:[],
  bookingPending:[],
  bookingTrainer: [],
  bookingTrainerRejected:[],
  bookingTrainerCompleted:[],
  bookingTrainerPending:[],
};

export const bookingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_BOOKING_ACCEPTED.start:
    case types.GET_BOOKING_ACCEPTED.success:
      return { ...state, isLoading: false, booking: payload };
    case types.GET_BOOKING_ACCEPTED.failed:
    case types.GET_BOOKING_REJECTED.start:
    case types.GET_BOOKING_REJECTED.success:
      console.log("payloadd....",payload)
      return { ...state, isLoading: false, bookingRejected: payload };
    case types.GET_BOOKING_REJECTED.failed:
    case types.GET_BOOKING_COMPLETED.start:
    case types.GET_BOOKING_COMPLETED.success:
      return { ...state, isLoading: false, bookingCompleted: payload };
    case types.GET_BOOKING_COMPLETED.failed:
    case types.GET_BOOKING_PENDING.start:
    case types.GET_BOOKING_PENDING.success:
      return { ...state, isLoading: false, bookingPending: payload };
    case types.GET_BOOKING_PENDING.failed:
      case types.GET_TRAINER_BOOKING_ACCEPTED.start:
    case types.GET_TRAINER_BOOKING_ACCEPTED.success:
      return { ...state, isLoading: false, bookingTrainer: payload };
    case types.GET_TRAINER_BOOKING_ACCEPTED.failed:
      case types.GET_TRAINER_BOOKING_REJECTED.start:
    case types.GET_TRAINER_BOOKING_REJECTED.success:
      return { ...state, isLoading: false, bookingTrainerRejected: payload };
    case types.GET_TRAINER_BOOKING_REJECTED.failed:
      case types.GET_TRAINER_BOOKING_COMPLETED.start:
    case types.GET_TRAINER_BOOKING_COMPLETED.success:
      return { ...state, isLoading: false, bookingTrainerCompleted: payload };
    case types.GET_TRAINER_BOOKING_COMPLETED.failed:
      case types.GET_TRAINER_BOOKING_PENDING.start:
    case types.GET_TRAINER_BOOKING_PENDING.success:
      return { ...state, isLoading: false, bookingTrainerPending: payload };
    case types.GET_TRAINER_BOOKING_PENDING.failed:
    default:
      return state;
  }
};
