
import {types} from "../actiontypes"

const inititalState={
    customer:[],
    trainer:[],
    isLoading:false
}


export const userReducer=(state=inititalState, {type,payload})=>{
    switch(type){
        case types.GET_CUSTOMERS.start:
            return {...state, isLoading:true}
        case types.GET_CUSTOMERS.success:
            return {...state, isLoading:false, customer:payload !== null ? payload : []}
        case types.GET_CUSTOMERS.failed:
            return {...state, isLoading:false}

        case types.GET_TRAINERS.start:
            return {...state, isLoading:true}
        case types.GET_TRAINERS.success:
            return {...state, isLoading:false, trainer:payload !== null ? payload : []}
        case types.GET_TRAINERS.failed:
            return {...state, isLoading:false}
        case types.DELETE_TRAINER.success:
            return { ...state, isLoading:false, payload}
        case types.DELETE_TRAINER.failed:
            return {...state, isLoading:false}
        case types.GET_TRAINER_ACTIVE:
            return {...state, isLoading:false, trainer:payload !== null ? payload : []}
            case types.TRAINER_UPDATED_SUCCESS.start:
                return {...state, isLoading:true}
            case types.TRAINER_UPDATED_SUCCESS.success:
                console.log("payload data....",payload)
                return {...state, isLoading:false, trainer:payload !== null ? payload : []}
            case types.TRAINER_UPDATED_SUCCESS.failed:
                return {...state, isLoading:false}
            // case types.GET_USER_PURCHASES.success:
            //     return {...state, isLoading:false,payload}
        default:
            return state

    }
}