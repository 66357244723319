import { types } from '../actiontypes'

const initialState = {
	isLoading: false,
	workoutList:[],

}

export  const workoutReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case types.GET_WORKOUT.start :
			return { ...state, isLoading: true }
		case types.GET_WORKOUT.success:
			return { ...state, isLoading: false, workoutList: payload }
        case types.GET_WORKOUT.failed :
            return { ...state, isLoading: false }

		case types.CREATE_WORKOUT.start :
			return { ...state, isLoading: true }
		case( types.CREATE_WORKOUT.success || types.CREATE_WORKOUT.failed) :
			return { ...state, isLoading: false}
		case types.EDIT_WORKOUT.start :
			return { ...state, isLoading: true }
		case( types.EDIT_WORKOUT.success || types.EDIT_WORKOUT.failed) :
			return { ...state, isLoading: false }
		default:
			return state
	}

}