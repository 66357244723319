import { types } from '../actiontypes'

const initialState = {
	isLoading: false,
	timeslotList:[],

}

export  const timeslotReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case types.GET_TIMESLOT.start :
		case types.CREATE_TIMESLOT.start :
		case types.EDIT_TIMESLOT.start :
			return { ...state, isLoading: true }
		
		case types.GET_TIMESLOT.success:
			return { ...state, isLoading: false, timeslotList: payload }
      
		case types.GET_TIMESLOT.failed :
		case types.CREATE_TIMESLOT.success :
	    case  types.CREATE_TIMESLOT.failed:
		case  types.EDIT_TIMESLOT.success:
		case types.EDIT_TIMESLOT.failed:
            return { ...state, isLoading: false }

		
		default:
			return state
	}

}