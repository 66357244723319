import { types } from "../actiontypes";

const initialState = {
  isLoading: false,
  authData: null,
  forgetPasswordEmail: "",
  fcmToken:""
};

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOGIN.start:
      return { ...state, isLoading: true };
    case types.LOGIN.success:
      return { ...state, isLoading: false, authData: payload };
    case types.LOGIN.success:
      return { ...state, isLoading: false };

    case types.FORGET_PASSSWORD.start:
      return { ...state, isLoading: true };
    case types.FORGET_PASSSWORD.success:
      console.log(
        "forget password reducer payload ----------------------------",
        payload
      );
      return { ...state, isLoading: false, forgetPasswordEmail: payload.email };
    case types.FORGET_PASSSWORD.success:
      return { ...state, isLoading: false };

    case types.UPDATE_PASSWORD.start:
      return { ...state, isLoading: true };
    case types.UPDATE_PASSWORD.success:
      return { ...state, isLoading: false, authData: payload };
    case types.UPDATE_PASSWORD.failed:
      return { ...state, isLoading: false };

      case types.UPDATE_ADMIN_PROFILE.start:
        return { ...state, isLoading: true };
      case types.UPDATE_ADMIN_PROFILE.success:
        console.log("admin------------------------------", payload)
        return { ...state, isLoading: false, authData:{...state.authData, ...payload} };
      case types.UPDATE_ADMIN_PROFILE.failed:
        return { ...state, isLoading: false };
    case types.LOGOUT.start:
      return { ...state, isLoading: true };
    case types.LOGOUT.success:
      return { ...state, isLoading: false, authData: null };
    case types.LOGOUT.success:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
