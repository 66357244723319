import { types } from "../actiontypes";

const initialState = {
  isLoading: false,
  purchases: [],
};

export const purchaseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_PURCHASES.start:

    case types.GET_PURCHASES.success:
      return { ...state, isLoading: false, purchases: payload };

    case types.GET_PURCHASES.failed:

    default:
      return state;
  }
};
