import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import settings from "./settings/Reducer";
import chatReducer from "./chats/Reducer";
import notesReducer from "./notes/Reducer";
import contactReducer from "./contacts/";
import maincontactReducer from "./contacts/Contacts";
import emailReducer from "./email/";
import maintodoReducer from "./todos/Todos";
import todoReducer from "./todos/";




// ========================== app ====================== //
import { persistStore, persistCombineReducers } from 'redux-persist';
import { authReducer, workoutReducer, timeslotReducer, toastReducer, userReducer , packageReducer,trainerReducer,purchaseReducer, bookingReducer } from "./reducer";

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};


const rootReducer=({
    settings,
    chatReducer,
    contactReducer,
    emailReducer,
    notesReducer,
    todoReducer,
    maintodoReducer,
    maincontactReducer,

    workout:workoutReducer,
    user:userReducer,
    auth:authReducer,
    toast:toastReducer,
    package: packageReducer,
    timeslot:timeslotReducer,
    trainer:trainerReducer,
    purchase:purchaseReducer,
    booking:bookingReducer
    
  });
  
  


const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

   export const store = createStore(persistedReducer, applyMiddleware(thunk))
   export const persistor = persistStore(store)
  
