const manageActionType = (type) => {
  return {
    start: type + "_Start",
    success: type + "_Success",
    failed: type + "_Failed",
  };
};

export const types = {
  // ==================== AUTH ======================//
  //signup
  SIGNUP: manageActionType("SIGNUP"),
  //login save id and token
  LOGIN: manageActionType("LOGIN"),
  LOGOUT: manageActionType("LOGOUT"),
  // to save reset verification code in reducer
  FORGET_PASSSWORD: manageActionType("FORGET_PASSSWORD"),
  //reset password from reducer
  RESET_PASSWORD: manageActionType("RESET_PASSWORD"),
  UPDATE_PASSWORD: manageActionType("UPDATE_PASSWORD"),

  GET_PROFILE: manageActionType("GET_PROFILE"),
  UPDATE_PROFILE: manageActionType("UPDATE_PROFILE"),

  GET_TRAINER: manageActionType("GET_TRAINER"),

  CREATE_PACKAGE_TYPE: manageActionType("CREATE_PACKAGE_TYPE"),
  EDIT_PACKAGE_TYPE: manageActionType("EDIT_PACKAGE_TYPE"),
  GET_PACKAGE_TYPE: manageActionType("GET_PACKAGE_TYPE"),

  CREATE_PACKAGE: manageActionType("CREATE_PACKAGE"),
  EDIT_PACKAGE: manageActionType("EDIT_PACKAGE"),
  GET_PACKAGE: manageActionType("GET_PACKAGE"),

  CREATE_WORKOUT: manageActionType("CREATE_WORKOUT"),
  EDIT_WORKOUT: manageActionType("EDIT_WORKOUT"),
  GET_WORKOUT: manageActionType("GET_WORKOUT"),

  CREATE_TIMESLOT: manageActionType("CREATE_TIMESLOT"),
  EDIT_TIMESLOT: manageActionType("EDIT_TIMESLOT"),
  GET_TIMESLOT: manageActionType("GET_TIMESLOT"),

  GET_REQUEST: manageActionType("GET_REQUEST"),
  GET_TRAINERS: manageActionType("GET_TRAINERS"),
  GET_CUSTOMERS: manageActionType("GET_CUSTOMERS"),
  DELETE_TRAINER: manageActionType("DELETE_TRAINER"),
  GET_TRAINER_ACTIVE: manageActionType("GET_TRAINER_ACTIVE"),
  TRAINER_UPDATED_SUCCESS: manageActionType("TRAINER_UPDATED_SUCCESS"),
  GET_PURCHASES:manageActionType("GET_PURCHASES"),
  GET_BOOKING_ACCEPTED:manageActionType("GET_BOOKING_ACCEPTED"),
  GET_BOOKING_REJECTED:manageActionType("GET_BOOKING_REJECTED"),
  GET_BOOKING_COMPLETED:manageActionType("GET_BOOKING_COMPLETED"),
  GET_BOOKING_PENDING:manageActionType("GET_BOOKING_PENDING"),
  GET_TRAINER_BOOKING_ACCEPTED:manageActionType("GET_TRAINER_BOOKING_ACCEPTED"),
  GET_TRAINER_BOOKING_REJECTED:manageActionType("GET_TRAINER_BOOKING_REJECTED"),
  GET_TRAINER_BOOKING_COMPLETED:manageActionType("GET_TRAINER_BOOKING_COMPLETED"),
  GET_TRAINER_BOOKING_PENDING:manageActionType("GET_TRAINER_BOOKING_PENDING"),
  GET_RESERVATION_TRAINER:manageActionType("GET_RESERVATION_TRAINER"),
  GET_RESERVATION_USER:manageActionType("GET_RESERVATION_USER"),
  UPDATE_PROFILE_BY_ID:manageActionType("UPDATE_PROFILE_BY_ID"),
  UPDATE_ADMIN_PROFILE:manageActionType("UPDATE_ADMIN_PROFILE"),

  UPLOAD_MEDIA: manageActionType("UPLOAD_MEDIA"),

  SHOW_TOAST: "SHOW_TOAST",
  HIDE_TOAST: "HIDE_TOAST",
  SET_FCMTOKEN: "SET_FCMTOKEN",
  REMOVE_FCMTOKEN: "REMOVE_FCMTOKEN"
};
