import { types } from '../actiontypes'

const initialState = {
	isLoading: false,
	packageList:[],
	packageTypeList:[]
}

export  const packageReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case types.GET_PACKAGE_TYPE.start :
			return { ...state, isLoading: true }
		case types.GET_PACKAGE_TYPE.success:
			return { ...state, isLoading: false, packageTypeList: payload !== null ? payload : [] }
        case types.GET_PACKAGE_TYPE.failed :
            return { ...state, isLoading: false }
			case types.GET_PACKAGE.start :
			return { ...state, isLoading: true }
		case types.GET_PACKAGE.success:
			return { ...state, isLoading: false, packageList: payload !== null ? payload : [] }
        case types.GET_PACKAGE.failed :
            return { ...state, isLoading: false }
        case types.CREATE_PACKAGE_TYPE.start :
            return { ...state, isLoading: true }
        case types.CREATE_PACKAGE_TYPE.success:
            return { ...state, isLoading: false,  }
        case types.CREATE_PACKAGE_TYPE.failed :
            return { ...state, isLoading: false }
		default:
			return state
	}

}